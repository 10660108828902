var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "readme-stats" },
    [
      !_vm.hideProfileCard
        ? _c("a", { attrs: { href: _vm.profileCardLink, target: "_blank" } }, [
            _c("img", {
              staticClass: "stats-card",
              attrs: { src: _vm.profileCard, alt: "Profile Card" },
            }),
          ])
        : _vm._e(),
      !_vm.hideLanguagesCard
        ? _c("a", { attrs: { href: _vm.profileCardLink, target: "_blank" } }, [
            _c("img", {
              staticClass: "stats-card",
              attrs: { src: _vm.topLanguagesCard, alt: "Languages" },
            }),
          ])
        : _vm._e(),
      _vm.repos
        ? _vm._l(_vm.repoCards, function (repo, i) {
            return _c(
              "a",
              { key: i, attrs: { href: repo.cardHref, target: "_blank" } },
              [
                _c("img", {
                  staticClass: "stats-card",
                  attrs: { src: repo.cardSrc, alt: repo },
                }),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }